import React, { useEffect } from "react";
import { namePathMain, namePathProducts, pathMain, pathProducts } from "../../common/config/paths";
import { TopPathWay } from "../../common/ui/TopPathWay";
import { ProductList } from "./organoids/ProductList";
import "./styles/ForIndex.css";

export const Products = () => {
  const topWay = [
    {name: namePathMain, path: pathMain},
    {name: namePathProducts, path: pathProducts},
  ]
  
    

  useEffect(() => {
    document.title = namePathProducts
  }, [])

  return (
    <>
      <div className="page_main_con" >
        <div style={{width: "100%", height: "100%", padding: "20px"}}>
          <TopPathWay list={topWay} />
          <p className="page_title_products gradient1">{namePathProducts}</p>
          <ProductList/>          
        </div>
      </div>
    </>
  );
};




