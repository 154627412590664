import "./idx.css"

interface ITopPathWay {
  list: {
    path: string,
    name: string
  }[],
  mainClass?: string
}

export const TopPathWay = ({list, mainClass = "idx_tpw_main_con"}: ITopPathWay) => {
  let lastIdx = list.length - 1
  return (
      <div className={mainClass}>
        {list.map((e, i) => (
          <div key={i}>
          {i < lastIdx ? 
          <>
            <span><a className="idx_tpw_item_first" href={e.path}>{e.name}</a></span>
            <span>&nbsp;{"-"}&nbsp;</span>
          </>: <span>{e.name}</span>
          }
          </div>
        ))}
      </div>
  )
}


