
import "../styles/PartnersBlock.css"

interface IPartnersBlock {
  list: {desc: string |JSX.Element, icon: string}[]
}

export const PartnersBlock = ({list}: IPartnersBlock) => {
  return (
    <div className="m_pb_main_con">
      {list.map((p, i) => (
        <div key={i}>
        <div className="m_pb_partner_con">
          <img src={p.icon} alt="" className="m_pb_partner_img"/>
          <span className="m_pb_partner_desc">{p.desc}</span>  
        </div>
        <hr className="m_pb_hr"/>
        </div>
      ))}
    </div>
  );
};
