//@ts-ignore
import axios from "axios";
import { unpackServerData } from "./functions";


interface IQuerySendMail {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFailMsg: React.Dispatch<React.SetStateAction<string>>,
  setOkMsg: React.Dispatch<React.SetStateAction<string>>,
  senderName: string,
  destName: string,
  destMail: string,
  title: string,
  text: string,
}

interface IQuerySendMail2 {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFailMsg: React.Dispatch<React.SetStateAction<string>>,
  setOkMsg: React.Dispatch<React.SetStateAction<string>>,
  formData: FormData
}


export const querySendMail = ({setLoading, setFailMsg, setOkMsg, senderName, destMail, destName, title, text}: IQuerySendMail) => {
    setLoading(true)

    let form = new FormData();
    form.append("dest_name", destName);
    form.append("dest_email", destMail);
    form.append("sender_name", senderName);
    form.append("title", title);
    form.append("text", text);


  axios.post("https://izba.kras-abs.ru/api/v3/abs_visitks/sendMail", form,{
    headers: {
      "Content-Type": "multipart/form-data",
    }
    // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
})
    // .then((res) => res.json())
    .then((res) => {
      console.log("err_ok:", res);
      const {data, failMsg} = unpackServerData(res, "result")
      if(data != null) {
        setOkMsg("Письмо отправлено")
      } else {
        setFailMsg(failMsg)
      }
      setLoading(false)
    })
    .catch((err) => {
      console.log("err_c:", err);
      setFailMsg(err.message)
      setLoading(false)
    });
};

export const querySendMail2 = ({setLoading, setFailMsg, setOkMsg, formData}: IQuerySendMail2) => {
  setLoading(true)
  axios.post("https://izba.kras-abs.ru/api/v3/abs_visitks/sendMail", formData
  // {"sender_name": "АБС-Визитка(Тест)",
  //   "title":" Новая заявка на работу (Сайт Визитка)",
  //   "text": "Новая заявка на работу  Имя: r Почта: r Желаемая должность: r ",    
  //   "dest_name": "Анастасия",
  //   "dest_email": "tarikmogucheltwin@gmail.com"}
  // axios.post("http://localhost:8033/abs_visitks/sendMail", formData
  ,{
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      }//"Content-Type": "multipart/form-data",
  }
  ).then((resp) => {
      let {data, failMsg} = unpackServerData(resp.data, "getContractorDemands")
      console.log("extarcted_demands:success:", data, failMsg)
      if(data != null) {
        setOkMsg("Письмо отправлено")
      } else {
        setFailMsg(failMsg)
      }
      setLoading(false)
    }).catch(e => {
      let msg = e.message
      console.log("extarcted_demands:error1:", e)
      if(e.response && e.response.data) {
        console.log("extarcted_demands:error1:", e.response.data)
        let data = unpackServerData(e.response.data)
        if(data.failMsg != "") {
          msg = data.failMsg
        }
      }
      setFailMsg(msg)
      setLoading(false)
    })
}