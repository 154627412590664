
import "../styles/HogIconAndNotice.css"

interface IHogIconAndNotice {
  icon: string,
  notice: string
}

export const HogIconAndNotice = ({icon, notice}: IHogIconAndNotice) => {
  return (
    <div className="ra_a_hian_main_con">
      <img className="ra_a_hian_icon" src={icon} alt="no icon"/>
      <p className="ra_a_hian_notice" dangerouslySetInnerHTML={{__html: notice}}></p>
    </div>  
  );
};




