import { useStore } from "effector-react";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { $products, queryProducts } from "../logics/products";
import { ProductListItem } from "../molecules/ProductListItem2";
import { ShowProductListItem } from "../molecules/ShowProductListItem";
import "../styles/ProductList.css"

export const ProductList = () => {

  const products = useStore($products)
 

  const [loading, setLoading] = useState(true)
  const [failMsg, setFailMsg] = useState("")
  const [showProductIdx, setShowProductIdx] = useState(-1)




  useEffect(() => {
    
    if(products.length == 0) {
      // setLoading(true)
     queryProducts({setLoading, setFailMsg})
    }
     // eslint-disable-next-line
  }, [])

  const onProductDbClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log(e)
    //@ts-ignore
    let idxStr: string = e.target.getAttribute("idx")
    let idx = parseInt(idxStr)
    console.log("idx:", idx)
    if(idx > -1) {
      setShowProductIdx(idx)
    }
  }

  const ProductsLoading = () => {
    return products.length > 0 ? <></> : 
    loading ? <><Spinner size="sm" animation="border" variant="primary"/> <span>Загрузка данных...</span></> : 
    failMsg != "" ? <span>{failMsg}</span> :
    <span>{"Продукты не обнаружены"}</span>
  }

  return (
    <>
      <div className="o_pl_main_con">
        {ProductsLoading()}
        {products.map((p, i) => (
          <ProductListItem key={i} product={p} setShowProductIdx={setShowProductIdx} idx={i} />
        ))}
      </div>
      {showProductIdx < 0 || showProductIdx >= products.length ? <></> : <ShowProductListItem product={products[showProductIdx]} setShowProductIdx={setShowProductIdx} />}
    </>
  );
};
