import "../styles/FeaturesGroupItem.css"

interface INumbersGroupItem {
  title: string,
  text: string,
}

export const NumbersGroupItem = ({title, text}: INumbersGroupItem) => {
  return (
    <div style={{display: "flex", flexDirection: "column", textAlign: "center", margin: "15px"}}>
      <div style={{color: "#000000", lineHeight: "37px", textAlign: "left", fontWeight: 500,  fontSize: "32px", display: "flow-root"}}>
        <div style={{left: "-22px", top: "18px", position: "relative", width: "10px",height: "10px", background: "#E07D34", borderRadius: "50%"}}></div>
        {title}
      </div>
      <span style={{color: "#E07D34", lineHeight: "16px", textAlign: "left", maxWidth: "144px" }}>{text}</span>
    </div>
  );
}
