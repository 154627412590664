import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImgSliderData } from "../../../common/ui/ImgSlider";
import { pathMain } from "../../../common/config/paths";
import { IProduct } from "../logics/types";

import Com1 from "../../../common/assets/icons/testIcons/Company1.png";
import Com2 from "../../../common/assets/icons/testIcons/Company2.png";
import Com3 from "../../../common/assets/icons/testIcons/Company3.png";
import { useHistory } from "react-router-dom";
import { elemID_TabContact2usConNameForm } from "../../../common/config/vars";

import "../styles/ShowProductListItem.css";
import { SatisfiedClients } from "../atoms/SatisfiedClients";

interface IShowProductListItem {
  product: IProduct,
  setShowProductIdx: React.Dispatch<React.SetStateAction<number>>
}

export const ShowProductListItem = ({product, setShowProductIdx}: IShowProductListItem) => {
  const [show, setShow] = useState(true)
  const history = useHistory()

  const onHide = () => {
    setShowProductIdx(-1)
    setShow(false)
  }

  let data = [
    {src: Com1, desc: "com1"},
    {src: Com2, desc: "com2"},
    {src: Com3, desc: "com3"},
  ] as ImgSliderData[]

  const onContact2usClicked = () => {
    history.push(`${pathMain}#${elemID_TabContact2usConNameForm}`)
  }

  return (
    <Modal show={show}  onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="m_spli_modal_body">
        <div>
          <Button variant="default" className="m_spli_go_back_btn" onClick={() => setShowProductIdx(-1)}>Вернуться</Button>
        </div>
        <p className="m_spli_name">{product.name}</p>
        <p className="m_spli_desc" dangerouslySetInnerHTML={{__html: product.description}}></p>
        <div className="m_spli_clients_con" style={{display: (product.satisfiedClients && product.satisfiedClients.length > 0 ? "flex" : 'none')}}>
          {/*<p className="m_spli_clients_title">Довольны внедрением:</p>
          <ImgSlider data={data} />*/}
          <p className="m_spli_clients_title">Довольны внедрением:</p>
          <SatisfiedClients icons={product.satisfiedClients ? product.satisfiedClients : []}/>
        </div>
        <div style={{display: "flex"}}>
          <Button variant="default" className="m_spli_contact_us_btn" onClick={onContact2usClicked}>Оставить заявку</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

