import "../styles/ExperienceItem.css"

interface IExperienceItem {
  title?: string,
  link: string
}

export const ExperienceItem = ({title = "Автоматизации ", link}: IExperienceItem) => {
  return (
    <li className="ra_a_ei_main_con">
      {/* <span className="ra_a_ei_title">{title}</span> */}
      <a href={link}target="_blank">{title}</a>
    </li>
  );
};




