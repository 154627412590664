import { NumbersGroupItem } from "../atoms/NumbersGroupItem";
import { ImgSlider2 } from "../../../common/ui/ImgSlider2";
import { ImgSliderCard } from "../../../common/ui/ImgSlider2/molecules/ImgSliderCard";
import "../styles/NumbersGroup.css"



interface INumbersGroupItemMP {
  numsNotice: string,
  numsTitle: string,
  itemTitle: string,
  itemText: string,
}

export const NumbersGroupItemMP = ({numsNotice, numsTitle, itemText, itemTitle}: INumbersGroupItemMP) => {

  return(
    <div className="m_ng_numbers_con_tel">
      <span className="m_ng_numbers_notice_tel">{numsNotice}</span>
      <span className="m_ng_numbers_title_tel">{numsTitle}</span>
      <span className="m_ng_numbers_item_title_tel">{itemTitle}</span>
      <span className="m_ng_numbers_item_text_tel">{itemText}</span>
    </div>
  )

}

const data = [
  <ImgSliderCard content={<NumbersGroupItemMP numsNotice="100+ Довольных клиентов" numsTitle="Немного цифр" itemText="Реализованных продуктов" itemTitle="45345+"  />} active={false} acticeClass="" cClass="is2_card2" key={0}/>,
  <ImgSliderCard content={<NumbersGroupItemMP numsNotice="100+ Довольных клиентов" numsTitle="Немного цифр" itemText="Реализовано индивидуальных решений" itemTitle="6242+"  />} active={false} acticeClass="" cClass="is2_card2" key={1}/>,
  <ImgSliderCard content={<NumbersGroupItemMP numsNotice="100+ Довольных клиентов" numsTitle="Немного цифр" itemText="Крупных компаний" itemTitle="1212+"  />} active={false} acticeClass="" cClass="is2_card2" key={2}/>,
] 

export const NumbersGroup = () => {
  const nList = [
    {title: "52337+", text: "Реализованных продуктов"},
    {title: "942+", text: "Реализовано индивидуальных решений"},
    {title: "5439+", text: "Активных пользователей"},
    {title: "1280+", text: "Крупных компаний"},
  ]

  return (
    <div className="row col-12 m_ng_main_con ">
      <div className="m_ng_main_sub_con">
        <div className="m_ng_numbers_desktop">
          <div className="m_ng_circle_small"></div>
          <div className="m_ng_numbers_con">
            <span className="m_ng_numbers_notice">100+ Довольных клиентов</span>
            <span className="m_ng_numbers_title">Немного цифр</span>
            <div className="m_ng_numbers_list_con">
              {nList.map((n, i) => (
                <NumbersGroupItem key={i} title={n.title} text={n.text} />
              ))}
            </div>
          </div>
          <div className="m_ng_circle_big"></div>
        </div>
        <div className="m_ng_numbers_tel">
          <div style={{position: "relative", top: "0", left: "0", width: "100%"}}>
            <div className="m_ng_circle_big_tel"></div>
            <ImgSlider2 data={data} classMainCon="is2_slide_con" hidePagination={false} />
            <div className="m_ng_circle_small_tel"></div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
