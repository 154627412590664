
export const pathPrefix: string = ""
export const pathMain: string = pathPrefix + "/"
export const pathAbout: string = pathPrefix + "/about"
export const pathProducts: string = pathPrefix + "/products"
export const pathClients: string = pathPrefix + "/clients"
export const pathRealAutomation: string = pathPrefix + "/real_automation"
export const pathVacancy: string = pathPrefix + "/vacancy"
export const pathConvoy: string = pathPrefix + "/convoy"

export const namePathMain: string = "Главная"
export const namePathAbout: string = "О компании"
export const namePathProducts: string = "Продукты"
export const namePathClients: string = "Клиенты"
export const namePathRealAutomation: string = "Центр реальной автоматизации"
export const namePathVacancy: string = "Вакансии"
export const namePathConvoy: string = "Сопровождение 1C"
