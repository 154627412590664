import { Carousel} from "react-bootstrap";
import { BannerItem} from "../atoms/BannerItem";
import IconStalled from "../../../common/assets/icons/IconStalled.png";
import IconHourglass from "../../../common/assets/icons/IconHourglass.png";
import IconPuzzle from "../../../common/assets/icons/IconPuzzle.png";
import "../styles/Banners.css"
const banners = [
  {icon: IconStalled, notice: "Есть информационная система, но что-то идет не так <strong>и процессы стопорятся</strong>"},
  {icon: IconHourglass, notice: "Рутинные процессы и операции <strong>отнимают массу времени</strong>"},
  {icon: IconPuzzle, notice: "Имеющиеся ресурсы системы <strong>не используются или используются не полностью</strong>"},
]

interface IBanners {
}

export const Banners = ({}: IBanners) => {
  return (
    <>
    <p className="ra_m_b_title">Если у вашей компании:</p>
    <div className="ra_m_b_main_con">
      {banners.map((b, i) => (
        <BannerItem key={i} icon={b.icon} notice={b.notice}/>
      ))}
    </div> 
    <div className="ra_m_b_main_con_mobile">
    <Carousel pause="hover" controls={false} indicators={true} className="ra_m_b_carousel" aria-orientation="horizontal">
      {banners.map((b, i) => (
        <Carousel.Item  key={i} >
        <BannerItem icon={b.icon} notice={b.notice}/>
        </Carousel.Item>
      ))}
    </Carousel>
    </div>
    
    </> 
  );
};




