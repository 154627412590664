import "../styles/ImgSliderCard.css"

interface IImgSliderCard {
    content: JSX.Element,
    active: boolean,
    acticeClass: string,
    cClass: string
}

export const ImgSliderCard = ({content, active, acticeClass, cClass}: IImgSliderCard) => {
    if(cClass == "") cClass = "is2_card"
    if(acticeClass == "") acticeClass = "is2_active-slide"

    return(
        <div className={cClass + " " + (active ? acticeClass : "")}>
            {content}
        </div>
    )
}
