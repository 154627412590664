
import React from "react";
import { InfoLI } from "../atoms/InfoLI";
import "../styles/Info.css"

interface IInfo {
  title: string,
  titleClass: string,
  list: {icon: string, text: string}[]
}

export const Info = ({title, list, titleClass}: IInfo) => {

  return (
    <div className="m_i_main_con" >
      <p className={titleClass}>{title}</p>
      <ul className="m_i_ul">
        {list.map((c, i) => (
          <InfoLI key={i} icon={c.icon} text={c.text} />
        ))}
      </ul>
    </div>
  );
};
