import IconHog from "../../../common/assets/icons/IconHog.png";
import "../styles/Hog.css"
import { HogIconAndNotice } from "../atoms/HogIconAndNotice";

const hogNotice = `Кабан – сильное, смелое, выносливое и упорное животное. Эти качества ценятся и в природе, и в бизнесе.
Специалистов-асов реальной автоматизации в шутку называют «реальными кабанами» за их способность решать самые сложные задачи,
 поэтому символом Центров реальной автоматизации стал дикий кабан.`

 const statusNotice = `Статус Центра реальной автоматизации подтверждает, что специалисты нашей компании прошли обучение и 
 сертификацию по разработанной фирмой 1C технологии работы и помогут провести грамотное предварительное обследование бизнес-процессов,
  подлежащих автоматизации, а затем предложат способы повышения эффективности бизнеса клиента, используя преимущества платформы 1С:Предприятие.`

interface IHog {
}

export const Hog = ({}: IHog) => {
  return (
    <div className="ra_m_h_main_con">
      <p className="ra_m_h_title">Партнера фирмы «1С», который имеет статус Центра реальной автоматизации, легко узнать по эмблеме с красным кабаном.</p>
      <HogIconAndNotice icon={IconHog} notice={hogNotice}/>
      <p className="ra_m_h_sub_title">{statusNotice}</p>
    </div>  
  );
};




