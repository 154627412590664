import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Slash } from 'react-bootstrap-icons';
import './styles/ForIndex.css'


interface IImgSliderCard {
    content: JSX.Element,
    active: boolean,
    acticeClass: string,
    cClass: string,
    key: number
}

interface IImgSlider2 {
    data?: JSX.Element[],
    currIdx?: number,
    hidePagination?: boolean,

    classMainCon?: string,
    classMainSubCon?: string,
    classSection?: string,
    classDataCon?: string,
}

interface IImgSliderCardImg {
    alt?: string,
    src: string,
    iClass: string,
}

const minSwipeDist = 100
// const maxSwipeDist = 300
// const minSwipeTimeMs = 100
// const maxSwipeTimeMs = 1000


export const ImgSliderCardImg = ({alt = "", src, iClass}: IImgSliderCardImg) => {
    return(
        <img src={src} alt={alt} className={iClass} ></img>
    )
}

export const ImgSliderCard = ({content, active, acticeClass, cClass, key}: IImgSliderCard) => {
    return(
        <div key={key} className={cClass + " " + (active ? acticeClass : "")}>
            {content}
        </div>
    )
}

export const ImgSlider2 = ({data = [], currIdx = -1, classMainCon= "is2_main_con", classMainSubCon = "is2_main_sub_con", classSection= "is2_section", classDataCon = "is2_cards-slider-wrapper", hidePagination=true}: IImgSlider2) => {
    const [curr, setCurr] = useState(0)

    const [startX, setStartX] = React.useState(-1);
    const [endX, setEndX] = React.useState(-1);

    useEffect(() => {
        setCurr(currIdx < data.length && currIdx > -1 ? currIdx : Math.floor(data.length/2))
         // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        setCurr(currIdx < data.length && currIdx > -1 ? currIdx : Math.floor(data.length/2))
         // eslint-disable-next-line
    }, [currIdx])

    const onBtnNextClicked = () => {
        setCurr(curr == data.length - 1 ? 0 : curr + 1)
    }

    const onBtnPrevClicked = () => {
        setCurr(curr == 0 ? data.length - 1 : curr - 1)
    }

    function handleTouchStart(e: React.TouchEvent<HTMLElement>) {
        setStartX(e.targetTouches[0].clientX)
        setEndX(e.targetTouches[0].clientX)
    }

    function handleTouchMove(e: React.TouchEvent<HTMLElement>) {
        setEndX(e.targetTouches[0].clientX)
    }

    function handleTouchEnd() {
        if(startX - endX > minSwipeDist) onBtnNextClicked();
        else if(startX - endX < -minSwipeDist) onBtnPrevClicked();
        console.log("end", startX, endX, startX - endX)
        setStartX(-1)
        setEndX(-1)
    }


    return(
        <>
        <div className={classMainCon}>
            <div className={classMainSubCon}>
                <Button onClick={onBtnPrevClicked} variant="default" className="is2_btn_prev"><ChevronLeft/></Button>
                <section className={classSection} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                    <div className="is2_cards-slider">
                        <div className={classDataCon} style={{'transform': `translateX( -${curr*(100/data.length)}%)`}}>
                            {data}
                        </div>
                    </div>
                </section>
                <Button onClick={onBtnNextClicked} variant="default" className="is2_btn_next"><ChevronRight/></Button>
            </div>
            <div hidden={hidePagination} style={{margin: "auto"}}>{curr+1}<Slash style={{fontSize: "30px", margin: "auto", padding: "0"}}/>{data.length}</div>
    
        </div>
        </>
    )
}