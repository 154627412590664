import React, { useEffect, useState } from "react";
//import { ProductList } from "./organoids/ProductList";
import IconJoinOurTeam from "../../../common/assets/icons/IconJoinOurTeam.png"
import { Form, Button, Spinner } from "react-bootstrap";
import "../styles/JobConditions.css"
import "../styles/JoinOurTeam.css"
import { Paperclip, Plus } from "react-bootstrap-icons";
import { JoinOurTeamInput } from "../atoms/JoinOurTeamInput";
import { destinationEmailName2, destinationEmail2, orderSenderName } from "../../../common/config/vars";
import { querySendMail2 } from "../../../common/sendMail";
import { toast } from "react-toastify";

const maxFileSize = 8388608 //байт

export const JoinOurTeam = () => {
  const [hideAttachFileBtn, setHideAttachFileBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [failMsg, setFailMsg] = useState("")
  const [okMsg, setOkMsg] = useState("")

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [position, setPosition] = useState("")
  const [file, setFile] = useState<File>()

  useEffect(() => {
    setHideAttachFileBtn(file == undefined ? false : true)
  }, [file])

  useEffect(() => {
    if(!loading){
      if(failMsg != "") toast.error(failMsg)
      else if(okMsg != "") toast.success(okMsg)
    }
     // eslint-disable-next-line
  }, [loading])

  const onSendBtnClicked = () => {
    if(name == "") {
      toast.error("Введите ваше имя")
      return
    } else if(email == "") {
      toast.error("Введите ваш почту")
      return
    } else if(position == "") {
      toast.error("Введите желаемую должность")
      return
    }
    let text = "Новая заявка на работу\n\r"+
    "Имя: " + name + " \n\r"+
    "Почта: " + email + " \n\r"+
    "Желаемая должность: " + position + " \n\r"

    let form = new FormData();
    form.append("dest_name", destinationEmailName2);
    form.append("dest_email", destinationEmail2);
    form.append("sender_name", orderSenderName);
    form.append("title", "Новая заявка на работу (Сайт Визитка)");
    form.append("text", text);
    // form.append("dest[]", JSON.stringify({name: destinationEmailName1, mail: "tarikmogucheltwin@gmail.com" }))

    // form.append("sender_name", orderSenderName);
    // form.append("subject", "Новая заявка на работу (Сайт Визитка)");
    // form.append("receiver_name", destinationEmailName1);
    // form.append("receiver_mail", "tarikmogucheltwin@gmail.com");
    // form.append("dest[]", JSON.stringify({name: destinationEmailName1, mail: "tarikmogucheltwin@gmail.com" }))


    //form.append("dest[]", JSON.stringify({name: destinationEmailName2, mail: destinationEmail2 }))
    if (file != undefined) form.append("files", file)
    querySendMail2({setLoading, setFailMsg, setOkMsg, formData: form})
  }

  const onDelAttachBtnClicked = () => {
    setFile(undefined)
  }

  const onBtnSetAttachClicked = () => {
    let inp = document.getElementById("inp-load-file");
    //@ts-ignore
    inp.click();
  };

    const onSelectedFilesChanged = (e: React.ChangeEvent<Element>) => {
      //@ts-ignore
      let flist: File[] = e.target.files;
      if (flist.length == 0){
        //@ts-ignore
        e.target.value = ""
        return
      } else if(flist[0].size > maxFileSize) {
        //@ts-ignore
        e.target.value = ""
        toast.error("Максимальные размер файла " + maxFileSize/(1024*1024) + " Mb")
        return
      }
      setFile(flist[0] as File)
      //@ts-ignore
      e.target.value = ""
    };

  return (
    <div className="v_m_jot_main_con">
      <p className="v_m_jot_title">Присоединяйся к нашей команде</p>
      <div className="v_m_jot_sub_con">
        <div className="v_m_jot_contacts_form">
          <JoinOurTeamInput title={"Ваше имя"} val={name} setVal={setName}  placholder={"Введите ваше имя"}/>
          <JoinOurTeamInput title={"Ваш Email"} val={email} setVal={setEmail}  placholder={"Введите ваш Email"}/>
          <JoinOurTeamInput title={"Желаемая должность"} val={position} setVal={setPosition}  placholder={"Введите название должности"}/>
          <div className="">
            <Form.File.Input id="inp-load-file" hidden={true} onChange={(e: React.ChangeEvent) => onSelectedFilesChanged(e)} max-size="5120" accept=".doc, .docx, .pdf"/>
            <Button hidden={hideAttachFileBtn} onClick={onBtnSetAttachClicked} variant="default" className="v_m_jot_attach_file_btn">
              <Paperclip className="v_m_jot_attach_file_btn_icon"/>
              <span className="v_m_jot_attach_file_btn_text">Прикрепите резюме</span>
            </Button>
            <div hidden={!hideAttachFileBtn} className="v_m_jot_show_attach_con">
              <p style={{margin: "auto auto auto 0", maxWidth: "260px", overflowX: "hidden"}} >{file != undefined ? file.name : ""}</p>
              <Button variant="default" className="v_m_jot_del_attach_btn" onClick={onDelAttachBtnClicked}>
                <Plus className="v_m_jot_del_attach_btn_icon"/>
                <p className="v_m_jot_del_attach_btn_text">Удалить</p>
              </Button>
            </div>
          </div>
          <div className="v_m_jot_send_con">
            <Button onClick={onSendBtnClicked} disabled={loading} variant="default" className="v_m_jot_send_btn">
              {loading ? <Spinner animation="border" size="sm"/> : "Отправить"}
            </Button>
          </div>
        </div>
        <img src={IconJoinOurTeam} alt="" className="v_m_jot_img" />
      </div>
    </div>
    
  );
};




