
import "../styles/InfoLI.css"

interface IInfoLI {
  icon: string,
  text: string
}

export const InfoLI = ({icon, text}: IInfoLI) => {

  return (
    <li className="a_ili_li">
      <img src={icon} alt="" className="a_ili_img" />
      <span className="a_ili_title">{text}</span>
    </li>
  );
};
