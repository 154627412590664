
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
// import "../styles/ConvoySatisfiedClients.css";

interface ISatisfiedClients {
  icons: string[]
}

export const SatisfiedClients = ({icons}: ISatisfiedClients) => {
  const refContainer = useRef(null);

  const onNextClicked = () => {
    //@ts-ignore
    if(refContainer != null && refContainer.current.scrollLeft < refContainer.current.scrollWidth) refContainer.current.scrollLeft += 30
  }

  const onPrevClicked = () => {
    //@ts-ignore
    if(refContainer != null && refContainer.current.scrollLeft > 30) refContainer.current.scrollLeft -= 30
  }
  return (
    <div style={{display: "flex", flexDirection: "row", margin: "auto"}}>
      <div hidden={true} style={{height: "100%", margin: "auto 0 auto auto"}}>
        <Button className="p_m_sc_data_slider_wrapper_prev" onClick={onPrevClicked}><ChevronLeft/></Button>
      </div>
      <div ref={refContainer} className="p_m_sc_data_slider_wrapper">
        {icons.map((c, i) => (
          <div key={i} className="p_m_sc_item">
            <img src={c} alt="" style={{width: "100px"}}/>
          </div>
        ))}
      </div>
      <div hidden={true} style={{height: "100%", margin: "auto auto auto 0"}}>
        <Button className="p_m_sc_data_slider_wrapper_next" onClick={onNextClicked}><ChevronRight/></Button>
      </div>
    </div>
  );
};
