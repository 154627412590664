import { createStore, createEffect } from "effector";
import axios from "axios";
// import IconClient2 from "../../../common/assets/icons/testIcons/client2.png";
// import IconClient4 from "../../../common/assets/icons/testIcons/client4.png";
// import IconClient9 from "../../../common/assets/icons/testIcons/client9.png";

// import IconProduct1 from "../../../common/assets/icons/testIcons/IconProduct1.png";
// import IconProduct2 from "../../../common/assets/icons/testIcons/IconProduct2.png";
// import IconProduct3 from "../../../common/assets/icons/testIcons/IconProduct3.png";
// import IconProduct4 from "../../../common/assets/icons/testIcons/IconProduct4.png";
// import IconProduct5 from "../../../common/assets/icons/testIcons/IconProduct5.png";
// import IconProduct6 from "../../../common/assets/icons/testIcons/IconProduct6.png";
// import IconProduct7 from "../../../common/assets/icons/testIcons/IconProduct7.png";
// import IconProduct8 from "../../../common/assets/icons/testIcons/IconProduct8.png";
// import IconProduct9 from "../../../common/assets/icons/testIcons/IconProduct9.png";
import { IProduct, IQueryProducts } from "./types";

export const $products = createStore<IProduct[]>([])

export const queryProducts = createEffect<IQueryProducts, IProduct[]>();

$products.on(queryProducts.doneData, (_, val) => val);




queryProducts.use(async ({setLoading, setFailMsg}) => {
var empty_pack=""

  await axios.post('https://izba.kras-abs.ru/api/visitka/product/byCompanyId', {com_id: 1}).then(res => {
    empty_pack= res.data})
  

//@ts-ignore
  return empty_pack.products.reverse() as IProduct[]
    // {id: 1, name: "1С Бухгалтерия", 
    //   logo_path: "" ,
    //   description: "Программа 1С:Бухгалтерия 8  – готовое решение для ведения бухгалтерского и налогового учета и подготовки обязательной (регламентированной) отчетности в хозрасчетных организациях и у индивидуальных предпринимателей. Программа поддерживает учет различных видов деятельности: производство, оптовая и розничная торговля, оказание услуг и т.п."}] as IProduct[]
  //   {id: 2, name: "Программа 1С:Управление торговлей", 
  //     satisfiedClients: [],
  //     logoPath: "",
  //     description: "Программа 1С:Управление торговлей 8 предназначена для повышения эффективности работы торговых предприятий. Результат достигается за счет автоматизации следующих направлений: управление продажами, управление запасами, управление закупками, денежные средства, финансовый учет, управление правилами продаж и маркетингом."},
  //   {id: 3, name: "Программа 1С:Зарплата и управление персоналом",
  //     satisfiedClients: [/*IconClient2, IconClient4*/ ],
  //     logoPath: "",
  //     description: "Программа 1С:Зарплата и управление персоналом 8  – автоматизирует задачи управления персоналом, ведение кадрового учета, расчет заработной платы, исчисление налогов и подготовку отчетности в соответствии с требованиями законодательства. Использование данного программного продукта позволит: значительно сократить временные затраты на обработку большого количества информации по кадрам и проводить анализ данных; вы сможете эффективно планировать и осуществлять кадровую политику на предприятии. Система позволит получать данные об управлении сотрудниками, проведении мероприятий по подбору и обучению персонала, оценивать квалификацию производственного и управленческого состава."},
  //   {id: 4, name: "Программа 1С:Управление небольшой фирмой",
  //     satisfiedClients: [],
  //     logoPath: "",
  //     description: "Программа 1С:Управление небольшой фирмой 8 - предназначена для использования на предприятиях следующих отраслей: оптовая и розничная торговля, производственная сфера, подрядные работы, оказание услуг. Это полноценный продукт, обеспечивающий ведение управленческого учета по каждой организации в отдельности или по всем, входящим в компанию, сразу."},
  //   {id: 5, name: "Программа 1С Комплексная автоматизация", 
  //     satisfiedClients: [],
  //     logoPath: "",
  //     description: "Программа 1С Комплексная автоматизация 8 - это многофункциональное прикладное решение, обладающее широкими возможностями для ведения управленческого, бухгалтерского и налогового учета на предприятиях различных сфер деятельности. Программа позволяет создать единую информационную систему, способствующую решению задач управления и учета как одной, так и нескольких, например, дочерних организаций. При этом регламентированная отчетность по каждой из фирм может формироваться раздельно, а использование общей информационной базы значительно упрощает процесс ведения учета."},
  //   {id: 6, name: "Программа 1С:CRM", 
  //   satisfiedClients: [],
  //     logoPath: "",
  //     description: "Программа 1С:CRM 8 - специализированное прикладное решение для автоматизации бизнес-процессов компании, связанных с обслуживанием клиентов: работы отдела продаж, закупок, реализации маркетинговых программ, контроля качества сервисного обслуживания и других. Программа позволяет создать единое информационное пространство компании с помощью интеграции «1С:CRM» и других популярных конфигураций на платформе «1С:Предприятие»: «1С:Бухгалтерия», «1С:Управление торговлей, «1С:Управление производственным предприятием» и другими решениями."},
  //   {id: 7, name: "Программа 1С:Документооборот", 
  //   satisfiedClients: [],
  //     logoPath: "",
  //     description: "Программа 1С:Документооборот 8 (1С СЭД 8.3) - это универсальный программный продукт, предназначенный для автоматизации документооборота компаний. Программа не имеет отраслевой специфики и успешно используется в коммерческих и бюджетных организациях. Система хорошо показала себя как в крупных холдингах с разветвленной структурой, так и небольших компаниях. Для производственных предприятий предусмотрена интеграция с 1С:ERP 2.0 и 1С:УПП 8. Благодаря своей универсальности система успешно настраивается под различные сферы деятельности."},
  //   {id: 8, name: "1С:УПРАВЛЕНИЕ АВТОТРАНСПОРТОМ", 
  //   satisfiedClients: [],
  //     logoPath: "",
  //   description: "Решение для автоматизации управленческого и оперативного учета в автотранспортных предприятиях и организациях, а также в автотранспортных подразделениях торговых, производственных и прочих предприятиях, использующих автотранспорт для собственных нужд."},

  //   {id: 9, name: "1С ERP. Управление холдингом", 
  //   satisfiedClients: [],
  //     logoPath: "",
  //   description: "«1С:ERP. Управление холдингом» — решение в линейке бизнес-приложений системы «1С:Предприятие 8», объединяющее в едином продукте автоматизацию трех уровней управления:</br></br>" +
  //  " <ul><li>управление эффективностью группы компаний (CPM);</li>" +
  //   "<li>управление ресурсами предприятий (ERP);</li>" +
  //   "<li>управление производством на уровне цеха (MES).</li></ul>" +
  //   "В единой системе на современной платформе «1С:Предприятие 8» можно управлять финансами, материальными потоками, персоналом и отношениями с контрагентами на всех уровнях.</br></br>" +
  //   "Корпоративный функционал аналогичен функционалу типового решения «1С:Управление холдингом», большинство подсистем которого разработаны при методологической поддержке EY, PwC, KPMG и используются более чем 400 клиентами, среди которых «Росатом», «Татнефть», «Зарубежнефть», «Новатэк», АФК «Система», «Объединенная судостроительная корпорация», «АвтоВАЗ», «Почта России», «Трансмашхолдинг», «Медси», «Абрау-Дюрсо».</br></br>" +
  //   "Функционал управления ресурсами предприятия аналогичен функционалу типового решения «1С:ERP Управление предприятием», который используют более 1000 предприятий. В программный продукт «1С:ERP. Управление холдингом» входят следующие подсистемы «1C:ERP»: регламентированный учет по РСБУ, управление производством и оптимизация планирования, организация ремонтов, управление затратами и расчет себестоимости, управление персоналом и расчет заработной платы, управление взаимоотношениями с клиентами, управление продажами, закупками, складом, запасами и доставкой."},
    
    // {id: 2, name: "1С:Зарплата и Управление Персоналом 8", description: "Решение для комплексной автоматизации кадрового учета и расчета зарплаты на небольших и средних предприятиях, в том числе имеющих сложную...", logoPath: null},
    // {id: 3, name: "1С Управление нашей фирмой 8", description: "Решение позволяет в единой системе вести базу клиентов, банковские и кассовые операции, расчеты с контрагентами и персоналом, учет заказов, продукции...", logoPath: null},
    // {id: 4, name: "1С Розница 8", description: "Программа предназначена для автоматизации торговой деятельности магазинов и других розничных торговых точек, в том числе объединенных в торговую сеть.", logoPath: null},
    // {id: 5, name: "1С:Комплексная автоматизация 8", description: "Прикладное решение позволяет построить современную систему учета с высокой степенью детализации затрат, определить показатели управления и ответственн....", logoPath: null},
   
})
