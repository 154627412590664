import React, { useEffect } from 'react';
import { OrderCallFormAndIcon } from '../molecules/OrderCallFormAndIcon';
import { FeaturesGroup } from '../molecules/FeaturesGroup';
import { NumbersGroup } from '../molecules/NumbersGroup';
import { AutomationGroup } from '../molecules/AutomationGroup';
import { OrgNameABS } from '../../../common/config/vars';
import { FeedbackGroup } from '../molecules/FeedbackGroup';

interface ISectionAutomation {
  hash: string
}


export const SectionAutomation = ({hash}: ISectionAutomation) => {
  useEffect(() => {
    document.title = OrgNameABS
  }, [])
  
  return (
    <section>
     <OrderCallFormAndIcon />
     <FeaturesGroup />
     <NumbersGroup />
     <AutomationGroup hash={hash}/>
     <FeedbackGroup  hash={hash}/>
    </section>
  );
}
