import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { $convoyproducts, queryConvoyProducts } from "../logics/convoyproduct";
import { ProductListItem,ShowProductListItem } from "../molecules/ConvoyProductListItem";
import "../styles/ConvoyProductList.css"

export const ConvoyProductList = () => {
    const convoyproducts = useStore($convoyproducts)

    const [loading, setLoading] = useState(true)
    const [failMsg, setFailMsg] = useState("")
    const [showConvoyProductIdx, setShowConvoyProductIdx] = useState(-1)

    
    
      useEffect(() => {
        if(convoyproducts.length == 0) {
          queryConvoyProducts({setLoading, setFailMsg})
        }
         // eslint-disable-next-line
      }, [])

      const ConvoyProductsLoading = () => {
        return convoyproducts.length > 0 ? <></> : 
        loading ? <><Spinner size="sm" animation="border" variant="primary"/> <span>Загрузка данных...</span></> : 
        failMsg != "" ? <span>{failMsg}</span> :
        <span>{"Продукты не обнаружены"}</span>
      }

    return (
        <>
        {ConvoyProductsLoading}
          <div className="o_сpl_main_con">
            {convoyproducts.map((p, i) => (
              <ProductListItem key={i} product={p} setShowProductIdx={setShowConvoyProductIdx} idx={i} />
            ))}
          </div>
          {showConvoyProductIdx < 0 || showConvoyProductIdx >= convoyproducts.length ? <></> : <ShowProductListItem product={convoyproducts[showConvoyProductIdx]} setShowProductIdx={setShowConvoyProductIdx} />}

        </>
      );
    };
    