
import {useEffect, useState} from "react"

export const r2json = (response: any) => {
  try {
      const json = response.json()
      return json;
  } catch (error) {
      console.log("tryParseJson:error: ", error)
      return null;
  }
}

export const empty = (v: any) => {
  return (v === null || v === undefined || (typeof(v) == "string" && v.length === 0) || (typeof(v) == "object" && Object.keys(v).length === 0)) ? true : false
}

export const kopecks2rubStr = (kopecks: number) =>{
  if(empty(kopecks)) return ""
  let formatter = Intl.NumberFormat("ru-RU", {style: "currency", currency: "RUB"});
  return formatter.format(kopecks/100)
}

export const useFetch = (url: string )=> {
  const [data, setData] = useState(null);
  const [fail, setFail] = useState(false);
  const [msg, _] = useState("");
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    const response = await fetch(url);
    const json = await response.json();
    console.log("fetch_res:", response)
    console.log("fetch_json:", json)
    setFail(!response.ok)
    setData(json);
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    return () => {}
    // eslint-disable-next-line
  }, []);

  return {loading, fail, data, msg};
};

export function date2str(date: Date, dateMark: string = '-', timeMark: string = ':') {
  if(isNaN(date.getTime())) return { date: '', time: '' };
	let dateStr = date.getFullYear() + dateMark + (date.getMonth() + 1).toString().padStart(2, '0')
   + dateMark + date.getDate().toString().padStart(2, '0');
	let dateTimeStr =  date.getHours().toString().padStart(2, '0') + timeMark
   + date.getMinutes().toString().padStart(2, '0') + timeMark + date.getSeconds().toString().padStart(2, '0');
	return { date: dateStr, time: dateTimeStr };
}

export function cutText(str: string, len: number = 70, markContinue: string = '...', markStop: string = ' '): string{
  if(empty(str)) return ''
  if(str.length < len) return str
  str = str.substr(0, len)
  let stopIdx = empty(markStop) ? str.length : str.lastIndexOf(markStop)
  return (stopIdx !== -1 ? str.substr(0, stopIdx) : str) + markContinue
}

export function cutTextTwoParts(str: string, markInto: string = '...', countFromStart: number = 18, countFromEnd: number = 18): string{
  if(empty(str)) return ''
  let strLen = str.length
  if(strLen < countFromStart + countFromEnd) return str
  return `${str.substr(0, countFromStart)}${markInto}${str.substr(-countFromEnd)}`
}

export function insert2str(str: string, idx: number, val: string): string {
  return str.substr(0, idx) + val + str.substr(idx);
}

export interface IValidatePhoneResult {
  phone: string,
  ok: boolean
}

export function validatePhone(str: string, pattern: string[], markNum: string): IValidatePhoneResult {
  if(empty(str)) str = ''
  str = str.replace(/ /gi, '')
  let ret  = str.split('')
  let pmaxi = pattern.length - 1
  let ok = true
  for(let i = 0; i <ret.length; ++i){
    if(pmaxi < i) {
      ok = false
      break
    }
    if(pattern[i] !== markNum) {
      if(ret[i] !== pattern[i]){
        str = insert2str(str, i, pattern[i]);
        ret = str.split('');
      }
    } else if(ret[i].match(/^\d+$/) == null) ok = false
  }
  if(ret.length !== pattern.length) ok = false
  return {phone: ret.join(''), ok: ok}
}

export function validatePhoneRU(phone: string): IValidatePhoneResult {
  return validatePhone(removeValidateMarksRU(phone), ['+', '7', '(', '','','',')','','','','-','','','-','',''], '')
}

export function removeValidateMarksRU(str: string): string {
  return empty(str) ? '' : str.replace(/[()-]/g, '')
}

export function isStrictNested(obj: object, props: string): boolean {
  if(props === '') return true
  var propsArr: Array<string> = props.split('.')
  var temp = obj
  for (var prop in propsArr) {
    //@ts-ignore
    if (temp[propsArr[prop]] === 'undefined' || !temp[propsArr[prop]]) return false
    //@ts-ignore
    temp = temp[propsArr[prop]]
  }
  return true
}
//@ts-ignore
export function isFail(data, reqProps: string = ''): { jsonData: any, fail: boolean, failMsg: string} {
  if("err_code" in data && "message" in data) {
    return { jsonData: data, fail: true, failMsg: data.message }
  }
  if(isStrictNested(data, reqProps)) {
    return { jsonData: data, fail: false, failMsg: ''}
  }
  return { jsonData: data, fail: true, failMsg: JSON.stringify(data)}
}

export function strictNestedObj(obj: object, props: string): any {
  if(props === '') return null
  var propsArr: Array<string> = props.split('.')
  var temp = obj
  for (var prop in propsArr) {
    //@ts-ignore
    if (temp[propsArr[prop]] === 'undefined' || !temp[propsArr[prop]]) return null
    //@ts-ignore
    temp = temp[propsArr[prop]]
  }
  return temp
}


export function unpackServerData(obj: any, reqProps: string = ''): { data: any, failMsg: string} {
  if("err_code" in obj && "message" in obj) return { data: obj, failMsg: obj.message }
  let successObj = strictNestedObj(obj, reqProps)
  if(successObj !== null) return { data: successObj, failMsg: ''}
  return { data: obj, failMsg: JSON.stringify(obj)}
}

// interface IQuerySendMail {
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>,
//   setFailMsg: React.Dispatch<React.SetStateAction<string>>,
//   setOkMsg: React.Dispatch<React.SetStateAction<string>>,
//   senderName: string,
//   destName: string,
//   destMail: string,
//   title: string,
//    text: string,
// }