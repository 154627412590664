import { IProduct } from "../logics/types";
import { IMAGES_URL } from "../../../common/config/vars";
import "../styles/ProductListItem2.css";

interface IProductsItem {
  product: IProduct,
  idx: number,
  setShowProductIdx: React.Dispatch<React.SetStateAction<number>>
}

export const ProductListItem = ({product, idx, setShowProductIdx}: IProductsItem) => {
  console.log(product)
  return (
    //@ts-ignore
    <div  className="m_pli2_main_con" onClick={() => setShowProductIdx(idx)} >
      <div className="m_pli2_title_con">
        <img src={product.logo_path == null ? "" : IMAGES_URL +product.logo_path} alt="" className="m_pli2_title_icon"/>
        <p className="m_pli2_title" >{product.name}</p>
      </div>
      <p className="m_pli2_desc" dangerouslySetInnerHTML={{__html: product.description}}></p>
    </div>
  );
};
