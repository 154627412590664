

export const mainColor: string = "#E07D34"

export const OrgNameABS = "ООО АБС"

export const contactUsTel: string = "+7 (391) 204-08-25"
export const contactUsEmail: string = "office@kras-abs.ru"

export const orderDestinationEmail: string = "sapeginaaa@kras-abs.ru"
export const orderDestinationName: string = "Анастасия"
export const orderSenderName: string = "АБС-Визитка(Тест)"

export const destinationEmail1: string = "sapeginaaa@kras-abs.ru"
export const destinationEmailName1: string = "Анастасия"

export const destinationEmail2: string = "office@kras-abs.ru" //mpsupport@kras-abs.ru
export const destinationEmailName2: string = "Сотрудник АБС"

export const elemID_TabContact2usConNameForm: string = "tab_contact2us_con_name_form"

export const IMAGES_URL: string = "https://kras-abs.ru/api/" 

