import React from "react";
import {NavLink} from "react-router-dom";
import {IHeaderNavList} from "../logics/navList";
import {DropdownMenu} from "../atoms";
import "../styles/index.css";

interface IHNavList {
    list: IHeaderNavList[],
    nClas?: string,
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
    noDropdown?: boolean
}

export const HNavList = ({list, setOpenMenu, nClas = "", noDropdown = false}: IHNavList) => {
  const onItemClicked = (main: boolean) => {
    setOpenMenu(false)
  }

  return (
    <nav className={nClas == "" ? "header_nav" : nClas} >
      {list.map((e, i) => (
        noDropdown ? <div key={i}>
            <NavLink key={i} onClick={() => onItemClicked(true)} exact={e.exact} to={e.path} className="header_nav_item" activeClassName="header_nav_item_active">{e.name}</NavLink>
            {e.sub.map((e, i1) => (
              <NavLink key={i1}  onClick={ () => onItemClicked(true)} exact={e.exact} to={e.path} className="header_nav_item" activeClassName="header_nav_item_active">{e.name}</NavLink>
            ))} 
          </div>:
        <DropdownMenu key={i} menu={e} setOpenMenu={setOpenMenu} />
        // i == 1 ? <MenuAboutCompany/>:
        // <NavLink key={i} onClick={onItemClicked} exact={e.exact} to={e.path} className="header_nav_item" activeClassName="header_nav_item_active">{e.name}</NavLink>
      ))}
    </nav>
  );
};
