
import { IConvoyProduct } from "../logics/types";
import { SatisfiedClients } from "../atoms/ConvoySatisfiedClients";
import { IMAGES_URL } from "../../../common/config/vars";

import "../styles/ConvoyProductListItem.css";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImgSliderData } from "../../../common/ui/ImgSlider";
import { pathMain } from "../../../common/config/paths";

import Com1 from "../../../common/assets/icons/testIcons/Company1.png";
import Com2 from "../../../common/assets/icons/testIcons/Company2.png";
import Com3 from "../../../common/assets/icons/testIcons/Company3.png";
import { useHistory } from "react-router-dom";
import { elemID_TabContact2usConNameForm } from "../../../common/config/vars";



interface IShowProductListItem {
  product: IConvoyProduct,
  setShowProductIdx: React.Dispatch<React.SetStateAction<number>>
}


interface IProductsItem {
  product: IConvoyProduct,
  idx: number,
  setShowProductIdx: React.Dispatch<React.SetStateAction<number>>
}

export const ProductListItem = ({product, idx, setShowProductIdx}: IProductsItem) => {
  console.log(product)
  return (
    //@ts-ignore
    <div  className="m_cpli_main_con" onClick={() => setShowProductIdx(idx)} >
      <div className="m_pli2_title_con">
        <img src={product.logo_path == null ? "" : IMAGES_URL +product.logo_path} alt="" className="m_pli2_title_icon"/>
        <p className="m_pli2_title" >{product.name}</p>
      </div>
      <p className="m_pli2_desc" dangerouslySetInnerHTML={{__html: product.description}}></p>
    </div>
  );
};

export const ShowProductListItem = ({product, setShowProductIdx}: IShowProductListItem) => {
  const [show, setShow] = useState(true)
  const history = useHistory()

  const onHide = () => {
    setShowProductIdx(-1)
    setShow(false)
  }

  let data = [
    {src: Com1, desc: "com1"},
    {src: Com2, desc: "com2"},
    {src: Com3, desc: "com3"},
  ] as ImgSliderData[]

  const onContact2usClicked = () => {
    history.push(`${pathMain}#${elemID_TabContact2usConNameForm}`)
  }

  return (
    <Modal show={show}  onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="m_spli_modal_body">
        <div>
          <Button variant="default" className="m_spli_go_back_btn" onClick={() => setShowProductIdx(-1)}>Вернуться</Button>
        </div>
        <p className="m_spli_name">{product.name}</p>
        <p className="m_spli_desc" dangerouslySetInnerHTML={{__html: product.description}}></p>
        <div className="m_spli_clients_con" style={{display: (product.satisfiedClients && product.satisfiedClients.length > 0 ? "flex" : 'none')}}>
          {/*<p className="m_spli_clients_title">Довольны внедрением:</p>
          <ImgSlider data={data} />*/}
          <p className="m_spli_clients_title">Довольны внедрением:</p>
          <SatisfiedClients icons={product.satisfiedClients ? product.satisfiedClients : []}/>
        </div>
        <div style={{display: "flex"}}>
          <Button variant="default" className="m_spli_contact_us_btn" onClick={onContact2usClicked}>Оставить заявку</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

