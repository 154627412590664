import React from "react";
import {NavLink} from "react-router-dom";
import {IHeaderNavList} from "../logics/navList";
import "../styles/index.css";
import "../styles/DropdownMenu.css";

interface IDropdownMenu {
  menu: IHeaderNavList,
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}

export const DropdownMenu = ({menu, setOpenMenu}: IDropdownMenu) => {
  const onItemClicked = (main: boolean) => {
    if(main) {
      if(menu.sub.length == 0){
        console.log("SER_OPEN:", menu)
        setOpenMenu(false)
      } 
    }
    else {
      console.log("SER_OPEN2", main)
      setOpenMenu(false)
    } 
  }

  return (
    <div>
    <div className={ menu.sub.length > 0 ? "h_atom_dm_main_con" : "h_atom_dm_main_con_no_sub"}>
          <div className="h_atom_dm_title_con">
            <NavLink  onClick={() => onItemClicked(true)} exact={menu.exact} to={menu.path} className="header_nav_item h_atom_dm_title" activeClassName="header_nav_item_active">{menu.name}</NavLink>
            {/* {menu.sub.length > 0 ? <ChevronDown/>: <></>} */}
          </div>
          {menu.sub.length == 0 ? <></> : 
          <div className="h_atom_dm_dropdown_con">
            <div style={{margin: "auto"}}>
            <NavLink  onClick={() => onItemClicked(true)} exact={menu.exact} to={menu.path} className="header_nav_item h_atom_dm_dropdown_item" activeClassName="header_nav_item_active">{menu.name}</NavLink>
            </div>
            {menu.sub.map((e, i) => (  
              <div  key={i} style={{margin: "auto"}}>
                <NavLink onClick={ () => onItemClicked(false)} exact={e.exact} to={e.path} className="header_nav_item h_atom_dm_dropdown_item" activeClassName="header_nav_item_active">{e.name}</NavLink>
              </div>
            ))}
          </div>
}
        </div>
    </div>
  );
};
