//@ts-ignore
import { createEvent, restore } from "effector";
import { namePathAbout,pathConvoy,namePathConvoy, namePathMain, namePathProducts, namePathRealAutomation, namePathVacancy, pathAbout,  pathMain, pathProducts, pathRealAutomation, pathVacancy } from "../../../config/paths"


export interface IHeaderNavList {
  path: string,
  name: string,
  exact: boolean
  sub: IHeaderNavList[]
}

export const setHeaderNavList = createEvent<IHeaderNavList[]>();
export const $headerNavList = restore<IHeaderNavList[]>(setHeaderNavList, 
    [
      {path: pathMain, name: namePathMain, exact: true, sub: []},
      {path: pathAbout, name: namePathAbout, exact: true, sub: []},
      {path: pathProducts, name: namePathProducts, exact: true, sub: []},
      {path: pathConvoy, name: namePathConvoy, exact: true, sub: []},
      //{path: pathClients, name: namePathClients, exact: true},
      {path: pathVacancy, name: namePathVacancy, exact: true, sub: []},
      {path: pathRealAutomation, name: namePathRealAutomation, exact: true, sub: []}
    ]
);

/*export const setCurrHeaderNavItemIdx = createEvent<number>();
export const $currHeaderNavItemIdx = restore<number>(setCurrHeaderNavItemIdx, -1)*/
