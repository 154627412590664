import "../styles/BannerItem.css"

interface IBannerItem {
  icon: string,
  notice: string
}

export const BannerItem = ({icon, notice}: IBannerItem) => {
  return (
    <div className="ra_a_bi_main_con">
      <img className="ra_a_bi_icon" src={icon} alt="no icon"/>
      <p className="ra_a_bi_notice" dangerouslySetInnerHTML={{__html: notice}}></p>
    </div>  
  );
};




