
//import { ProductList } from "./organoids/ProductList";
import IconConditions from "../../../common/assets/icons/IconVacancyConditions.png"
import "../styles/JobConditions.css"
const conditions = [
  {id: 1, name: "Профессиональный рост и развитие"},
  {id: 2, name: "Трудоустройство в соответствии с ТК РФ с первого дня"},
  {id: 3, name: "Заработная плата выплачивается 2 раза в месяц"},
  {id: 4, name: "Стабильная и конкурентная заработная плата (по итогам собеседования)"},
  {id: 5, name: "Возможность не только работать и зарабатывать, но и каждый день развивать себя, как профессионала!"},
  {id: 6, name: "График работы: понедельник – пятница, с 9:00 до 18:00"},
]

export const JobConditions = () => {

  return (
    <div className="" style={{display: "flex", flexDirection: "row"}}>
      <img src={IconConditions} alt="" className="v_m_jc_conditions_img" />
      <div className="v_m_jc_conditions_list_con">
        <p className="v_m_jc_conditions_title">МЫ ПРЕДЛАГАЕМ</p>
        <ul className="v_m_jc_ul">
          {conditions.map((c, i) => (
            <li className="v_m_jc_li" key={i}>{c.name}</li>
          )) }
        </ul>
      </div>
    </div>
  );
};




