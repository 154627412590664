
import {ExperienceItem} from "../atoms/ExperienceItem";
import "../styles/Experience.css"

interface IExperience {
}

const experiences = [
  {title: `Автоматизация  учета рабочего времени в "1С: ЗУП 8" для ООО «Вербицкие»`, link: "https://1c.ru/solutions/partner/solution/1103260"},
  {title: `Внедрение "1С: Управление автотранспортом ПРОФ" для ООО «Кайрос»`, link: "https://1c.ru/solutions/partner/solution/1105675"},
  {title: `Внедрение "1С: Управление автотранспортом 8 ПРОФ" для ООО "Сибуголь"`, link: "https://1c.ru/solutions/partner/solution/1033470"},
]

export const Experience = ({}: IExperience) => {
  return (
    <div className="ra_m_e_main_con">
      <p className="ra_m_e_title">Наш опыт решения задач бизнеса:</p>
      <ul className="ra_m_e_ul">
        {experiences.map((e, i) => (
          <ExperienceItem key={i} title={e.title} link={e.link}/>
        ))}
      </ul>
    </div>  
  );
};




