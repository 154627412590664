import React from "react";
import { Form} from "react-bootstrap";
import "../styles/AutomationInputContainerInput.css"

interface IAutomationInputContainerInput {
  title: string,
  placholder: string,
  setVal: React.Dispatch<React.SetStateAction<string>>,
  val: string
}

export const AutomationInputContainerInput = ({title, placholder, setVal, val}: IAutomationInputContainerInput) => {
  return (
    <>
      <label style={{textAlign: "left"}}>{title}</label>
      <Form.Control type="text" value={val} onChange={e => setVal(e.target.value)} placeholder={placholder} className="m_a_aici_contacts_form_inp" />
    </>    
  );
};




