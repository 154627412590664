import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import './ForIndex.css'
/*
export const SliderData = [
    {
      src:
        'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
        desc: "car1"
    },
    {
        src:
        'https://images.unsplash.com/photo-1501446529957-6226bd447c46?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80',
        desc: "car1"
        
    },
    {
        src:
        'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        desc: "car1"
    },
    {
        src:
        'https://images.unsplash.com/photo-1475189778702-5ec9941484ae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80',
        desc: "car1"
    },
    {
        src:
        'https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        desc: "car1"
    }
  ] as ImgSliderData[];*/

  interface IImgSliderCard {
      alt: string;
      src: string;
      active: boolean
      imgWidth: string,
      imgHeight: string,
  }


  export const ImgSliderCard = ({alt, src, active, imgWidth}: IImgSliderCard) => {
    return(
        <div className={"card " + (active ? "active-slide" : "")}>
            <img src={src} alt={alt} style={{margin: "30px", width: imgWidth, height: "auto"}} ></img>
        </div>
    )
  }

export interface ImgSliderData {
    src: string;
    desc: string;
}

interface IImgSlider {
    data?: ImgSliderData[],
    currIdx?: number,
    imgWidth?: string,
    imgHeight?: string,
}




export const ImgSlider = ({data = [], currIdx = -1, imgWidth = "140px", imgHeight = "140px"}: IImgSlider) => {

    const [curr, setCurr] = useState(0)
    const [touchStart, setTouchStart] = React.useState(-1);
    const [touchEnd, setTouchEnd] = React.useState(-1);
    const [dataLen, setDataLen] = React.useState(data.length);

    useEffect(() => {
        setDataLen(data.length)
        setCurr(currIdx < data.length && currIdx > -1 ? currIdx : Math.floor(data.length/2))
         // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        setCurr(currIdx < dataLen && currIdx > -1 ? currIdx : Math.floor(dataLen/2))
         // eslint-disable-next-line
    }, [currIdx])

    const onBtnNextClicked = () => {
        setCurr(curr == dataLen - 1 ? 0 : curr + 1)
    }

    const onBtnPrevClicked = () => {
        setCurr(curr == 0 ? dataLen - 1 : curr - 1)
    }

    //@ts-ignore
    function handleTouchStart(e) {
        console.log("start-->", e.targetTouches[0].clientX)
        setTouchStart(e.targetTouches[0].clientX);
        setTouchEnd(e.targetTouches[0].clientX);
    }
    //@ts-ignore
    function handleTouchMove(e) {
        console.log("moving-->", e.targetTouches[0].clientX)
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        console.log("end", touchStart, touchEnd)
        if (touchStart - touchEnd > 150) {
            // do your stuff here for left swipe
            //console.log("prev")
            onBtnNextClicked();
            //onBtnPrevClicked();
        }

        if (touchStart - touchEnd < -150) {
            // do your stuff here for right swipe
            //console.log("next")
            //onBtnNextClicked();
            onBtnPrevClicked();
        }
        setTouchEnd(-1)
        setTouchStart(-1)
    }

    function onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        console.log("mouse_down", e)
    }
   

    return(
        <section className="" 
            style={{width: "100%", height: "200px", overflow: "hidden", position: "relative", margin: "auto"}}
            onTouchMove={handleTouchMove}  
            onTouchStart={handleTouchStart} 
            onMouseDown={onMouseDown }
            onTouchEnd={handleTouchEnd}>
            
            <div className="con-prev"><Button className="btn-prev" onClick={onBtnPrevClicked}><ChevronLeft style={{margin: "auto", padding: "0", fontWeight: "bold"}}/></Button></div>
            <div className="con-next"> <Button className="btn-next" onClick={onBtnNextClicked} ><ChevronRight/></Button></div>
            <div className="cards-slider">
                <div className="cards-slider-wrapper" style={{'transform': `translateX( -${curr*(100/dataLen)}%)`}}>
                    {data.map((e, i) => (
                        <ImgSliderCard  key={i} alt={e.desc} src={e.src} active={curr == i} imgWidth={imgWidth} imgHeight={imgHeight} ></ImgSliderCard>
                    ))}
                </div>
            </div>
        </section>
    )
}