
import { FeedbackInfo } from "./FeedbackInfo";
import "../styles/FeedbackGroupItem.css"

interface IFeedbackGroupItem {
  icon: string,
  feedback: string,
  fio: string,
  position: string,
  comName: string
}

export const FeedbackGroupItem = ({icon, feedback, fio, position, comName} : IFeedbackGroupItem) => {

  return (
    <div className="m_fgi_main_con"> {/*m_fgi_card m_fgi_active-slide */}
      {/* <div className="m_fgi_trapezoid"></div> */}
      <FeedbackInfo icon={icon} feedback={feedback} fio={fio} position={position} comName={comName}/>
    </div>
  );
}
