import React from "react";
import { Form} from "react-bootstrap";
import "../styles/JoinOurTeamInput.css"

interface IJoinOurTeamInput {
  title: string,
  placholder: string,
  setVal: React.Dispatch<React.SetStateAction<string>>,
  val: string
}

export const JoinOurTeamInput = ({title, placholder, setVal, val}: IJoinOurTeamInput) => {
  return (
    <>
      <label style={{textAlign: "left", width: "100%"}}>{title}</label>
      <Form.Control type="text" value={val} onChange={e => setVal(e.target.value)} placeholder={placholder} className="v_a_joti_contacts_form_inp" />
    </>    
  );
};




